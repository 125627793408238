import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import RootElement from './src/components/RootElement'
import './static/fonts/fonts.css'
import './src/styles/main.css'

function trackPageView({ location }) {
  if (
    process.env.NODE_ENV !== 'production' ||
    process.env.GATSBY_GA_SEND_PAGE_VIEWS !== 'true'
  ) {
    return null
  }

  const pagePath = location
    ? location.pathname + location.search + location.hash
    : undefined

  setTimeout(() => {
    if (typeof window.gtag === 'function') {
      window.gtag('event', 'page_view', { page_path: pagePath })
    }
  }, 100)
}

// NOTE: The official documentation for SPA does not work. See custom solution ↓
// Leave official solution for referencing.
// @see https://developer.onetrust.com/onetrust/docs/single-page-applications
// function reloadOneTrustBanner() {
//   var otConsentSdk = document.getElementById('onetrust-consent-sdk')
//   if (otConsentSdk) {
//     otConsentSdk.remove()
//   }
//   if (window.OneTrust != null) {
//     window.OneTrust.Init()
//     setTimeout(function () {
//       window.OneTrust.LoadBanner()
//       var toggleDisplay = document.getElementsByClassName(
//         'ot-sdk-show-settings',
//       )
//       for (var i = 0; i < toggleDisplay.length; i++) {
//         toggleDisplay[i].onclick = function (event) {
//           event.stopImmediatePropagation()
//           window.OneTrust.ToggleInfoDisplay()
//         }
//       }
//     }, 1000)
//   }
// }
// function clearDup() {
//   var sec = document.getElementById('ot-sdk-cookie-policy')
//   if (!sec) return
//   var tally = []
//   for (var i = sec.length - 1; i >= 0; i--) {
//     if (tally[sec[i].firstChild.innerText] === undefined) {
//       tally[sec[i].firstChild.innerText] = 1
//     } else {
//       console.log(i, sec[i].firstChild.innerText)
//       sec[i].remove()
//       // return true;
//     }
//   }
//   // return false;
// }

// NOTE: The official solution does not seem to work. The custom solution checks
// if the cookie list already has content (on page load) or needs to be
// initialized (navigated to a page with cookie list).
const customOneTrustInitializeCookiePolicyHtml = () => {
  if (process.env.GATSBY_STORYBLOK_VERSION !== 'published') return

  const elem = document.getElementById('ot-sdk-cookie-policy')
  setTimeout(() => {
    if (elem && elem.innerText === '') {
      if (
        window.OneTrust &&
        typeof window.OneTrust.initializeCookiePolicyHtml === 'function'
      ) {
        window.OneTrust.initializeCookiePolicyHtml()
      }
    }
  }, 1000)
}

export const wrapRootElement = ({ element }) => {
  return (
    <RootElement>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY}
        scriptProps={{
          async: true,
          appendTo: 'head',
        }}
      >
        {element}
      </GoogleReCaptchaProvider>
      {process.env.GATSBY_ENV_BADGE && (
        <div
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            borderRadius: '8px',
            color: '#eff6ff',
            paddingLeft: '0.5rem',
            paddingRight: '0.5rem',
            paddingTop: '0.25rem',
            paddingBottom: '0.25rem',
            fontSize: '0.75rem',
            lineHeight: '1rem',
            fontWeight: 500,
            backgroundColor: '#3b82f6',
            position: 'fixed',
            bottom: '2px',
            right: '2px',
          }}
        >
          {process.env.GATSBY_ENV_BADGE}
        </div>
      )}
    </RootElement>
  )
}

export const onRouteUpdate = ({ location }) => {
  // clearDup()
  // reloadOneTrustBanner()
  customOneTrustInitializeCookiePolicyHtml()
  trackPageView({ location })

  return true
}
