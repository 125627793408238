import React, { Fragment, useState } from 'react'
import { Script } from 'gatsby'

const RootElement = ({ children }) => {
  const embedProductionScripts =
    process.env.GATSBY_STORYBLOK_VERSION === 'published'
  const [consentLoaded, setConsentLoaded] = useState(false)
  const [consentAutoBlokLoaded, setConsentAutoBlokLoaded] = useState(false)

  const gaConfig = {
    anonymize_ip: process.env.GATSBY_GA_ANONYMIZE_IP
      ? process.env.GATSBY_GA_ANONYMIZE_IP === 'true'
      : false,
    cookie_expires: !Number.isNaN(Number(process.env.GATSBY_GA_MEASUREMENT_ID))
      ? Number(process.env.GATSBY_GA_MEASUREMENT_ID)
      : 63072000,
    send_page_view: true,
  }

  // TODO: Exclude /test/** pages from google analytics
  // window.excludeGtagPaths=[/^(?:\/test\/(?:(?!(?:\/|^)\.).)*?)$/];

  return (
    <Fragment>
      {embedProductionScripts && (
        <Fragment>
          <Script
            src={`https://cdn.cookielaw.org/consent/${process.env.GATSBY_ONETRUST_DOMAIN_SCRIPT}/OtAutoBlock.js`}
            onLoad={() => setConsentAutoBlokLoaded(true)}
          />
          <Script
            src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
            data-domain-script={process.env.GATSBY_ONETRUST_DOMAIN_SCRIPT}
            data-document-language="true"
            onLoad={() => setConsentLoaded(true)}
          />
          <Script id="__onetrust">{`function OptanonWrapper() {}`}</Script>
        </Fragment>
      )}
      {consentLoaded && consentAutoBlokLoaded && (
        <Fragment>
          <Script
            src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GATSBY_GA_MEASUREMENT_ID}`}
          />
          <Script id="__gtag-config" forward={[`gtag`]}>
            {`
          function gaOptout(){document.cookie=disableStr+'=true; expires=Thu, 31 Dec 2099 23:59:59 UTC;path=/',window[disableStr]=!0}var gaProperty='G-TK4GPETV1E',disableStr='ga-disable-'+gaProperty;document.cookie.indexOf(disableStr+'=true')>-1&&(window[disableStr]=!0);
          if(!(navigator.doNotTrack == "1" || window.doNotTrack == "1")) {
            window.dataLayer = window.dataLayer || [];
            function gtag(){window.dataLayer && window.dataLayer.push(arguments);}
            gtag('js', new Date());
            
            gtag('config', '${process.env.GATSBY_GA_MEASUREMENT_ID}', {"anonymize_ip":${gaConfig.anonymize_ip},"cookie_expires":${gaConfig.cookie_expires},"send_page_view":${gaConfig.send_page_view}, "page_path": location ? location.pathname + location.search + location.hash : undefined});
          }
          `}
          </Script>
        </Fragment>
      )}
      {children}
    </Fragment>
  )
}

export default RootElement
